[class^="col-"], [class*=" col-"] {
  float: left; }

.row {
  *zoom: 1; }
  .row:after, .row:before {
    content: " ";
    display: table; }
  .row:after {
    clear: both; }
