[class^="col-"], [class*=" col-"] {
  float: left; 
}

.row {
  *zoom: 1;
  &:after,
  &:before {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

